export const environment = {
  production: false,
  panel: "my11circle_",
  // domain: "mango777.club",
  // baseUrl: "https://api.mango777.club",
  domain: "kanhaexch.com",
  baseUrl: "https://api.kanhaexch.com",
  pollers: {
      oddsData: 600,
      listOddsData: 10000,
      scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  imgPrefix: '/my11circle',
  guestEnable: false,
  darkMode: false,
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  host: {
      domain: '',
      name: '',
      dName: '',
      ext: '',
      theme: '',
      email: '',
      pusher: {
          key: '',
          cluster: ''
      },
      themePath: [''],
      guestEnable: false,
      ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
      afLoginRedirectUrl: '',
      newHomePage: false,
      isCustomBanner: false
  },
  hosts: [
      {
          domain: 'localhost',
          name: 'localhost',
          dName: 'LocalHost',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'betwayexch.com',
          name: 'betwayexch',
          dName: 'betwayexch',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'raddyanna.bet',
          name: 'raddyanna',
          dName: 'raddyanna',
          ext: '.bet',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'raddyanna.com',
          name: 'raddyanna',
          dName: 'raddyanna',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'dynamo.bet',
          name: 'dynamo',
          dName: 'dynamo',
          ext: '.bet',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'bigbull69.bet',
          name: 'bigbull69',
          dName: 'bigbull69',
          ext: '.bet',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'mybet999.com',
          name: 'mybet999',
          dName: 'mybet999',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'runexch365.run',
          name: 'runexch365',
          dName: 'runexch365',
          ext: '.run',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'khandada9.com',
          name: 'khandada9',
          dName: 'khandada9',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'royalbets999.com',
          name: 'royalbets999',
          dName: 'royalbets999',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'gopal.bet',
          name: 'gopal',
          dName: 'gopal',
          ext: '.bet',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'shyam.bet',
          name: 'shyam',
          dName: 'shyam',
          ext: '.bet',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'gs999.online',
          name: 'gs999',
          dName: 'gs999',
          ext: '.online',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'omexch.bet',
          name: 'omexch',
          dName: 'omexch',
          ext: '.bet',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'eagle999.co',
          name: 'eagle999',
          dName: 'eagle999',
          ext: '.co',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'batballs247.com',
          name: 'batballs247',
          dName: 'batballs247',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
      {
          domain: 'jazbaexch.com',
          name: 'jazbaexch',
          dName: 'jazbaexch',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          pusher: {
              key: '5b1dec503fbd6e8b176c',
              cluster: 'eu'
          },
          themePath: ['assets/css/blue.css'],
          guestEnable: false,
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          afLoginRedirectUrl: '/dashboard',
          newHomePage: false,
          isCustomBanner: true
      },
  ]
};
