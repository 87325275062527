import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthGuard, RedirectGuard } from "./auth/guard";
import { AuthGuardGuest } from "./auth/guard/auth.guard-guest";
import { LayoutComponent } from "./layout/layout.component";
import { MaintenanceModeComponent } from "./auth/maintenance-mode/maintenance-mode.component";

const routes: Routes = [
  { path: '', pathMatch: 'full', children: [], canActivate: [RedirectGuard] },
  {
    path:"service-unavailable",
    component: MaintenanceModeComponent
  },
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "guest",
    loadChildren: () =>
      import("./guest/guest.module").then((m) => m.GuestModule),
    canActivate: [AuthGuardGuest],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
